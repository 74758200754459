import * as React from 'react';
import { useState, useCallback, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import * as Icon from "react-bootstrap-icons";
import { useGetPRDQuery, useCreateAppFromPRDMutation } from '../../../api/apiSlice';
import PRDForm from './PRDForm';
import { useSelector, useDispatch } from 'react-redux';
import { setShowEditModal } from '../../../reducers/prdSlice';
import Editor from "@monaco-editor/react";
import { Nav } from 'react-bootstrap';

const TimelineItem = ({ status, content, icon, files }) => {
    return(
        status === 'File Generation' ? 
        <Files files={files} status={status} icon={icon} /> : 
        <>
                <div className="timeline-item">
                    <div className="g-3 row">
                        <div className="col-auto">
                            <div className="position-relative timeline-separator">
                                <div className="icon-item-md border border-translucent bg-body icon-item icon-item-sm">
                                    {icon}
                                </div>
                                <span className="h-100 border-dashed timeline-bar border-end" />
                            </div>
                        </div>
                        <div className="col">
                            <div className="timeline-content">
                                <div className="d-flex justify-content-between">
                                    <div className="d-flex mb-2">
                                        <h6 className="lh-sm mb-0 me-2 text-body-secondary timeline-item-title">
                                            {status}
                                        </h6>
                                    </div>
                                </div>
                                <div className="fs-9 text-body-secondary w-sm-60 mb-0 mb-5">
                                    {content}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </>
    )
};
const Files = ({ files, status, icon }) => {
    return (
        <div className="timeline-item">
            <div className="timeline-item">
                    <div className="g-3 row">
                        <div className="col-auto">
                            <div className="position-relative timeline-separator">
                                <div className="icon-item-md border border-translucent bg-body icon-item icon-item-sm">
                                    {icon}
                                </div>
                                <span className="h-100 border-dashed timeline-bar border-end" />
                            </div>
                        </div>
                        <div className="col">
                            <div className="timeline-content">
                                <div className="d-flex justify-content-between">
                                    <div className="d-flex mb-2">
                                        <h6 className="lh-sm mb-0 me-2 text-body-secondary timeline-item-title">
                                           {status}
                                        </h6>
                                    </div>
                                </div>
                                <div className="fs-9 text-body-secondary w-sm-60 mb-0 mb-5">
                                    {files && Object.entries(files).map(([filePath, content], index) => {
                                        let extractedContent = content;
                                        let language = 'javascript'; 
                                        const codeMatches = {
                                            javascript: content.match(/```javascript([\s\S]*?)```/),
                                            html: content.match(/```html([\s\S]*?)```/),
                                            jsx: content.match(/```jsx([\s\S]*?)```/),
                                            markdown: content.match(/```markdown([\s\S]*?)```/),
                                            js: content.match(/```js([\s\S]*?)```/),
                                            tsx: content.match(/```tsx([\s\S]*?)```/),
                                            typescript: content.match(/```typescript([\s\S]*?)```/)
                                        };

                                        for (const [lang, match] of Object.entries(codeMatches)) {
                                            if (match) {
                                                extractedContent = match[1];
                                                language = ['js', 'jsx', 'tsx', 'typescript'].includes(lang) ? 'javascript' : lang;
                                                break;
                                            }
                                        }

                                        return (
                                            <div key={index}>
                                                <p>{filePath}</p>
                                                <Editor
                                                    height="200px"
                                                    language={language}
                                                    theme="vs-dark"
                                                    value={extractedContent.trim() || ''}
                                                    options={{ readOnly: true }}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    );
};

const PRDDisplay = () => {
    const navigate = useNavigate();
    const {token} = useSelector(state => state.login);
    const {showEditModal} = useSelector(state => state.prd);
    const { stream, files, complete } = useSelector(state => state.app);
    const { uuid } = useParams();
    const { data, isLoading, isError, refetch } = useGetPRDQuery({ prd_id: uuid, token: token });
    const [createAppFromPRD, createAppFromPRDResult] = useCreateAppFromPRDMutation();
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState('prd');
   

    useEffect(() => {
        if (complete) {
            refetch();
        }
    }, [complete, refetch]);

    useEffect(() => {
        if (data?.prd?.app?.uuid && data.prd.app.uuid) {
            navigate(`/!/ide/${data?.prd?.app?.uuid}`);
        }
    }, [data, navigate]);

    const renderLogContent = useCallback((status, output) => {
        let icon;
        switch (status) {
            case 'Generating app structure':
                icon = <Icon.Diagram3 className="fs-9 text-primary" />;
                break;
            case 'App Structure':
            case 'App structure generated':
                icon = <Icon.Building className="fs-9 text-success" />;
                break;
            case 'Creating app':
            case 'App created':
                icon = <Icon.Plus className="fs-9 text-info" />;
                break;
            case 'Collection Creation':
            case 'Collection Created':
                icon = <Icon.Database className="fs-9 text-warning" />;
                break;
            case 'Field Creation':
                icon = <Icon.InputCursor className="fs-9 text-danger" />;
                break;
            case 'Generating frontend':
            case 'Frontend Planning':
                icon = <Icon.Laptop className="fs-9 text-primary" />;
                break;
            case 'UI Design':
                icon = <Icon.Palette className="fs-9 text-success" />;
                break;
            case 'File Plan':
            case 'File Generation':
                icon = <Icon.FileEarmark className="fs-9 text-info" />;
                break;
            default:
                icon = <Icon.Circle className="fs-9 text-secondary" />;
        }

        return (
            <TimelineItem 
                status={status} 
                content={output} 
                icon={icon} 
                files={files} 
            />
        );
    }, [files]);

    if (isLoading) return <div>Loading PRD...</div>;
    if (isError) return <div>Error loading PRD</div>;

    const handleEditClick = () => dispatch(setShowEditModal({showEditModal: true}));
    const handleCloseModal = () => dispatch(setShowEditModal({showEditModal: false}));

    const handleStartBuild = async () => {
        setActiveTab('buildLogs');
        try {
            await createAppFromPRD({
                prd_uuid: uuid,
                token: token
            });
        } catch (error) {
            console.error('Error starting build:', error);
        }
    };

    return (
        <div className="container-fluid">
            <div className="row mb-3">
                <div className="col">
                    <Button
                        variant="primary"
                        size="sm"
                        onClick={handleStartBuild}
                        disabled={createAppFromPRDResult.isLoading}
                    >
                        <Icon.RocketTakeoff className="me-2" size={16}/>
                        {createAppFromPRDResult.isLoading ? 'Building...' : 'Start building your app'}
                    </Button>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div style={{ height: 'calc(100vh - 150px)', display: 'flex', flexDirection: 'row' }}>
                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                            <Nav variant="tabs" className="border-0">
                                {createAppFromPRDResult.isLoading || activeTab === 'buildLogs' ? (
                                    <Nav.Item
                                        style={{ backgroundColor: activeTab === 'buildLogs' ? '#141824' : '', color: activeTab === 'buildLogs' ? '#fff' : '' }}
                                    >
                                        <Nav.Link
                                            active={activeTab === 'buildLogs'}
                                            onClick={() => setActiveTab('buildLogs')}
                                            className="px-4 py-3"
                                        >
                                            Build Logs
                                        </Nav.Link>
                                    </Nav.Item>
                                ): null}
                                <Nav.Item
                                    style={{ backgroundColor: activeTab === 'prd' ? '#141824' : '', color: activeTab === 'prd' ? '#fff' : '' }}
                                >
                                    <Nav.Link
                                        active={activeTab === 'prd'}
                                        onClick={() => setActiveTab('prd')}
                                        className="px-4 py-3 d-flex align-items-center justify-content-between"
                                    >
                                        <span>PRD</span>
                                        <Dropdown as="span" className="ms-2">
                                            <Dropdown.Toggle variant="link" id="dropdown-basic" style={{ border: 'none', boxShadow: 'none', color: activeTab === 'prd' ? '#fff' : '' }}>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={handleEditClick}>Make changes to PRD</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <div style={{ flex: 1, overflow: 'auto', backgroundColor: '#141824' }}>
                                {activeTab === 'buildLogs' ? (
                                    <div style={{ height: '100%', overflow: 'auto', padding: '20px' }}>
                                        <div className="mb-9 timeline-basic">
                                            {Object.entries(stream).map(([status, { output }], index) => (
                                                <React.Fragment key={index}>
                                                    {stream[status] && renderLogContent(status, output)}
                                                </React.Fragment>
                                            ))}
                                        </div>
                                    </div>
                                ) : activeTab === 'prd' ? (
                                    <div style={{ height: '100%', overflow: 'auto', padding: '20px' }}>
                                        <div dangerouslySetInnerHTML={{ __html: data?.prd?.prd?.replace(/\n/g, '<br />') }} />
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={showEditModal} onHide={handleCloseModal} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Edit PRD</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PRDForm content={data?.prd} onSave={handleCloseModal} refetch={refetch} />
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default PRDDisplay;