export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const OAUTH_URL = process.env.REACT_APP_OAUTH_URL;
export const APP_API_KEY = process.env.REACT_APP_API_KEY;
export const API_URL = process.env.REACT_APP_API_URL;

export const style = {
    leftPadding: {
        paddingLeft: "5px"
    },
    sideBar: {
        minHeight: "calc(100vh)",
        height: "100%"
    }
};


export const generatePassword = () => {
    const chars = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const passwordLength = 12;
    let password = "";
    for (let i = 0; i <= passwordLength; i++) {
        const randomNumber = Math.floor(Math.random() * chars.length);
        password += chars.substring(randomNumber, randomNumber +1);
    }
    return password;
}

export const stripHtml = (html) => {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
}

export const capitalize = (string)  => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const parseSelectOptions = (options) => {
    const optionsArr = options.split(",");
    let parsedOption = [];
    for(let i = 0; i < optionsArr.length; i++) {
        parsedOption.push(optionsArr[i].trim());
    }
    return parsedOption;
}

export const default_validation = (values) => {
    let errors = {};
    if (typeof values?.required === "undefined" ) {
        errors.required = "You forgot to check if this property is required."
    }

    if (typeof values?.allow_duplicates === "undefined" ) {
        errors.allow_duplicates = "You forgot to check if this property allows duplicates."
    }

    if (typeof values?.editable === "undefined" ) {
        errors.editable = "You forgot to check if this property is editable."
    }
    return errors;
}

export const allowedHtmlTags = [
    'a',
    'b',
    'blockquote',
    'br',
    'caption',
    'cite',
    'col',
    'colgroup',
    'dd',
    'div',
    'dl',
    'dt',
    'em',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'i',
    'img',
    'li',
    'ol',
    'p',
    'pre',
    'q',
    'small',
    'span',
    'strike',
    'strong',
    'sub',
    'sup',
    'table',
    'tbody',
    'td',
    'tfoot',
    'th',
    'thead',
    'tr',
    'u',
    'ul'
];

export const property_details_headers = (fields=[]) => {
    let headers = ["property"];
    for (let i = 0; i < fields?.length; i++) {
        let keys = Object.keys(fields[i]);
        for (let j = 0; j < keys.length; j++) {
            if (!headers.includes(keys[j]) && !["uuid"].includes(keys[j]) && keys[j] !== "name") {
                headers.push(keys[j]);
            }
        }
    }
    return headers;
};

export const validate_field_name = (field, fields=[]) => {
    let error = undefined;
    for (let i = 0; i < fields?.length; i++) {
        if (fields[i].name === field.name && fields[i].uuid !== field.uuid) {
            error = field?.name + " is already in use."
            break;
        }
    }

    if (!field?.name || field?.name?.trim() === "") {
        error = "You forgot to enter a name."
    }
    return {
        name: error
    };
}

export const bodyObject = (fields=[]) => {
    let body = {};
    for (let i = 0; i < fields?.length; i++) {
        body[fields[i]?.name?.toLowerCase()] = "your_" + fields[i]?.name?.toLowerCase();
    }
    return body;
}

export const emails = [
    {
        subject: "Welcome to Graxle Platform! 🚀",
        body: `
      <html>
        <body>
          <p>Hi [first_name],</p>
          <p>Welcome to Graxle Platform! I'm thrilled to have you on board.</p>
          <p>Our Platform empowers you with AI-powered tools to turn your ideas into fully functional apps in record time. Whether you're building for business or personal projects, we're here to make your development process seamless and inspiring.</p>
          <p>If you have any questions, please email me at <a href="mailto:chinedu@graxle.com">chinedu@graxle.com</a>.</p>
          <p>Happy building!</p>
          <p>Chinedu, Founder & CEO</p>
        </body>
      </html>
    `,
        timestamp: new Date().toISOString()
    },
    {
        subject: "Getting Started with Graxle Platform",
        body: `
      <html>
        <body>
          <p>Hi [first_name],</p>
          <p>Now that you're part of the Graxle Platform, let's get you started on your app development journey!</p>
          <p>Familiarize yourself with the workspace. Here's how to create your first app:</p>
          <p>Start by describing your app idea using Graxle Copilot. Our AI will then create a detailed Product Requirements Document for you, which when approved will be used by Copilot to autogenerate API endpoints and a React frontend for your app.</p>
          <p>Have any questions or feedback? Reach out to me at <a href="mailto:chinedu@graxle.com">chinedu@graxle.com</a></p>
          <p>Happy developing!</p>
          <p>Best,<br>Chinedu, Founder & CEO of Graxle</p>
        </body>
      </html>
    `,
        timestamp: new Date(Date.now() + 1 * 60 * 60 * 1000).toISOString() // 1 hour later
    },
    {
        subject: "The Inspiration Behind Graxle Platform",
        body: `
      <html>
        <body>
          <p>Hi [first_name],</p>
          <p>Curious about the story behind Graxle Platform?</p>
          <p>I created this platform with a passion for merging AI and software development. I noticed the challenges developers and entrepreneurs face in rapidly bringing their ideas to life, and I wanted to provide a solution that makes the app development process more accessible and efficient.</p>
          <p>Graxle's AI-powered platform is designed to be an incredibly easy-to-use tool that enhances your productivity, giving you the freedom to focus on innovation rather than getting bogged down in repetitive coding tasks.</p>
          <p>Thank you for being part of this journey. Email me with suggestions at <a href="mailto:chinedu@graxle.com">chinedu@graxle.com</a></p>
          <p>Warm regards,<br>Chinedu, Creator of Graxle</p>
        </body>
      </html>
    `,
        timestamp: new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString() // 1 day later
    },
    {
        subject: "How's Your Experience with Graxle Platform?",
        body: `
      <html>
        <body>
          <p>Hi [first_name],</p>
          <p>I hope you're enjoying your experience with Graxle Platform so far!</p>
          <p>I'd love to hear about your journey and how I can help you make the most out of the platform. Have you tried generating a PRD or creating API endpoints yet? Here's a link to the <a href="https://docs.graxle.com/!/learn">documentation</a> to get you started. If you have any questions or need assistance, don't hesitate to reach out.</p>
          <p>Your feedback is crucial to me. Let me know how the platform is working for you and what features you'd like to see in the future.</p>
          <p>Looking forward to your response.</p>
          <p>Warm regards,<br>Chinedu, Creator of Graxle</p>
        </body>
      </html>
    `,
        timestamp: new Date(Date.now() + 3 * 24 * 60 * 60 * 1000).toISOString() // 3 days later
    }
];