import * as React from "react";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {Speedometer, CheckAll} from "react-bootstrap-icons";

const Landing = () => {
    const {isAuthenticated} = useSelector(state => state.login);
    const navigate = useNavigate();
    React.useEffect(() => {
        if ( isAuthenticated ) navigate("/!");
    })
    return(
        <>
            <div className="container h-100">
                <section className="pb-8" id="home">
                    <div className="container-small hero-header-container px-lg-7 px-3">
                        <div className="row justify-content-center">
                            <div className="col-lg-8 col-12 text-center pb-6 position-relative">
                                <div className="text-white">
                                    <h1 className="fs-3 fs-lg-2 fs-md-1 fs-lg-2 fs-xl-1 fw-black mb-4">Turn ideas into apps instantly</h1>
                                    <p className="mb-5">Graxle auto-generates API endpoints and provides a functional frontend, accelerating development and cutting down time-to-market from months to days.</p>
                                    <a className="btn btn-lg btn-outline-light rounded-pill" href="#features">Currently in private beta</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="bg-soft pt-15 pb-18" id="features">
                    <div className="container-small px-lg-7 px-xxl-3">
                        <div className="position-relative z-index-2">
                            <div className="mb-12 row">
                                <div className="text-center text-lg-start pe-xxl-3 col-lg-6">
                                    <h4 className="text-primary fw-bolder mb-4">Features</h4>
                                    <h2 className="mb-3 text-white lh-base">Revolutionize Your App Development Process</h2>
                                    <p className="mb-5">Graxle's AI-powered platform streamlines the entire app creation journey, from concept to deployment.</p>
                                </div>
                                <div className="mt-7 text-center text-lg-start col-lg-3 col-sm-6">
                                    <div className="h-100 d-flex flex-column justify-content-between">
                                        <div className="border-start-lg border-dashed ps-4">
                                            <Speedometer size={32} title="Fast" color="white" />
                                            <div className="mt-4">
                                                <h5 className="fw-bolder mb-2">Fast Development</h5>
                                                <p className="fw-semi-bold lh-sm">Go from idea to functional app in minutes, with a React frontend starter and flexible API endpoints.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-7 text-center text-lg-start col-lg-3 col-sm-6">
                                    <div className="h-100 d-flex flex-column">
                                        <div className="border-start-lg border-dashed ps-4">
                                            <CheckAll size={32} title="Complete" color="white" />
                                            <div className="mt-4">
                                                <h5 className="fw-bolder mb-2">Full-Stack Solution</h5>
                                                <p className="fw-semi-bold lh-sm">Generate a product requirements document (PRD), auto-generate API endpoints, and get started with React frontend code using AI.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Landing;
