import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Editor from "@monaco-editor/react";
import * as Icon from "react-bootstrap-icons";
import { Alert } from 'react-bootstrap';
import { fetchAppFromS3, downloadAppFilesFromS3 } from '../../../api/apiSlice';
import { useGetFileUploadAccessQuery } from '../../../api/apiSlice';
import { useParams } from 'react-router-dom'; 
import FileTreeView from './FileTreeView';
import { fetchFileContentFromS3 } from '../../../api/apiSlice';

const AIEditor = () => {
    const dispatch = useDispatch();
    const {appFiles, fileLoadError, currentFile, fileContent, fileContentError, downloadComplete, downloadError} = useSelector((state) => state.app);
    const {token} = useSelector((state) => state.login);
    const {uuid} = useParams();
    const {
        data: settings,
        isLoading,
        isSuccess,
    } = useGetFileUploadAccessQuery({
        "token": token,
    });

    useEffect(() => {
        if ( isSuccess && settings?.hasOwnProperty("IdentityId")) {
            dispatch(fetchAppFromS3({
                "app_uuid": uuid,
                "settings": settings
            }));
        }
    }, [isSuccess, settings, dispatch, uuid]);

    useEffect(() => {
        if (currentFile) {
            dispatch(fetchFileContentFromS3({
                "app_uuid": uuid,
                "settings": settings,
                "path": currentFile
            }));
        }
    }, [currentFile, uuid, settings, dispatch]);

    // Function to handle changes in the editor
    const handleEditorChange = (value) => {
        // Here you would typically dispatch an action to update the file content in your Redux store
        // For example: dispatch(updateFileContent(value));
        console.log('Editor content changed:', value);
    };

    const handleDownload = () => {
        dispatch(downloadAppFilesFromS3({
            "app_uuid": uuid,
            "settings": settings
        }));
    };

    return (
       <>
        {isLoading && <div>Loading...</div>}
        <div className="position-fixed top-0 start-0 p-3" style={{ zIndex: 1050 }}>
            {fileContentError && (
                <Alert variant="danger" className="mb-2">
                    {fileContentError}
                </Alert>
            )}
            {downloadComplete && (
                <Alert variant="success" className="mb-2">
                    Download completed successfully!
                </Alert>
            )}
            {downloadError && (
                <Alert variant="danger" className="mb-2">
                    {downloadError}
                </Alert>
            )}

            {fileLoadError && (
                <Alert variant="danger" className="mb-2">
                    {fileLoadError}
                </Alert>
            )}
        </div>
        <div className="d-flex content-min-h">
            <div className="sidebar" style={{ width: '250px', borderRight: '1px solid #ccc' }}>
                <div className="p-3">
                    <div className="nav nav-tabs mb-3 border-bottom d-flex justify-content-between align-items-center">
                        <div className="nav-item">
                            <button className="nav-link active" aria-current="page">
                                <Icon.Window className="me-2" style={{ color: 'highlight' }} /> FRONTEND
                            </button>
                        </div>
                        <button onClick={handleDownload} className="btn btn-sm btn-outline-light border-0">
                            <Icon.Download size={16} />
                        </button>
                    </div>
                    <FileTreeView files={appFiles} />
                </div>
            </div>
            <div className="flex-grow-1 content-min-h">
                {currentFile ? (
                    <Editor
                        height="100%"
                        defaultLanguage="javascript"
                        value={fileContent}
                        onChange={handleEditorChange}
                        theme="vs-dark"
                    />
                ) : (
                    <div className="flex-center content-min-h vstack">
                        <div className="text-center py-9">
                            <Icon.FileEarmarkCode
                                size={45}
                                className="text-body-secondary mb-3"
                                alt="blank code base"
                            />
                            <p>Select a file to start editing</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
       </>
    );
};

export default AIEditor;