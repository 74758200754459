import * as React from "react";
import * as Icon from 'react-bootstrap-icons';
import {io} from "socket.io-client";
import {generatePassword, OAUTH_URL, emails} from "../../constants";
import jwtDecode from "jwt-decode"; // Updated import
import {useDispatch, useSelector} from 'react-redux';
import {loginUser} from "../../reducers/loginSlice";
import { useNavigate } from "react-router-dom";
import {useIsValidUserMutation, useSignUpMutation, useSendNotificationMutation} from "../../api/apiSlice";
const socket = io(OAUTH_URL, { transports: ['websocket', 'polling', 'flashsocket'] });

const Auth = () => {
    const {isAuthenticated} = useSelector(state => state.login);
    const [signedIn, setSignedIn] = React.useState(false);
    const [popup, setPopup] = React.useState({});
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);
    const cta = urlParams.get('cta')
    const [signUp, signUpResult] = useSignUpMutation();
    const [validateUser, validateUserResult] = useIsValidUserMutation();
    const [sendEmail] = useSendNotificationMutation();

    React.useEffect(() => {
        socket.on('google', token => {
            // if CTA is register, call the register endpoint
            const user = jwtDecode(token); // Updated call
            if ( cta === "register" ) {
                signUp({
                    "first_name": user?.first_name,
                    "last_name": user?.last_name,
                    "email": user?.email,
                    "photo": user?.photo,
                    "password": generatePassword()
                });
            } else {
                validateUser({
                    "email": user?.email,
                    "tokenize": true
                })
            }
        });

        return () => {
            socket.off('google');
        };
    }, [cta, signUp, validateUser]);

    React.useEffect(() => {
        if (signUpResult?.status === 'fulfilled' && signUpResult?.data?.token) {
            emails.forEach(email => {
                const user = jwtDecode(signUpResult?.data?.token);
                email.body = email.body.replace("[first_name]", user?.first_name);
                email.user = user;
            });
            sendEmail({
                messages: emails
            });

            dispatch(loginUser({token: signUpResult?.data?.token, user: jwtDecode(signUpResult?.data?.token)})); // Updated call
            setSignedIn(true);
        }

        if (validateUserResult?.status === 'fulfilled' && validateUserResult?.data?.token) {
            dispatch(loginUser({token: validateUserResult?.data?.token, user: jwtDecode(validateUserResult?.data?.token)})); // Updated call
            setSignedIn(true);
        }
    }, [dispatch, signUpResult, validateUserResult, sendEmail])

    React.useEffect(() => {
        if ((signedIn || validateUserResult?.isError === true || signUpResult?.isError === true) && typeof popup.close === "function" ) {
            popup.close();
        }
        const timer = setInterval(() => {
            if ( typeof popup.closed !== "undefined" && popup.closed === true && isAuthenticated ) {
                clearInterval(timer);
                navigate("/!");
            }
        }, 500);

        if (isAuthenticated && typeof popup.close !== "function") navigate("/!");
    }, [signedIn, popup, isAuthenticated, signUpResult, validateUserResult, navigate]);

    const openPopup = () => {
        const width = 600, height = 600;
        const left = (window.innerWidth / 2) - (width / 2);
        const top = (window.innerHeight / 2) - (height / 2);
        const url = `${OAUTH_URL}/google?socketId=${socket.id}`;

        return window.open(url, '',
            `toolbar=no, location=no, directories=no, status=no, menubar=no, 
                  scrollbars=no, resizable=no, copyhistory=no, width=${width}, 
                  height=${height}, top=${top}, left=${left}`
        );
    }

    const startAuth = (e) => {
        validateUserResult?.reset();
        signUpResult?.reset();
        e.preventDefault();
        const popup = openPopup();
        setSignedIn(false);
        setPopup(popup);
    }

    return(
        <main className="form-signin w-50 m-auto text-center p-5">
            <>
                <h1 className="h3 mb-3 fw-normal">It's free to get started</h1>
                <button className="w-100 btn btn-lg btn-outline-primary" onClick={startAuth} disabled={signUpResult?.isLoading}>
                    <Icon.Google />
                    <span className="p-2">{cta === "register" ? "Sign up": "Sign in"} with Google</span>
                </button>
                {
                    cta === "authenticate" ?
                    <p className="mt-5 mb-3 text-muted">New to Graxle? <a href="/auth?cta=register">Sign up</a></p> :
                    <p className="mt-5 mb-3 text-muted">Already have an account? <a href="/auth?cta=authenticate">Sign in</a></p>
                }
                <p className="mt-5 mb-3 text-muted">© Graxle 2023</p>
                {typeof signUpResult.error !== 'undefined' &&
                    signUpResult?.error?.data?.errors?.map((error, index) => {
                        return (
                            <div className="bg-warning" key={index}>
                                {error}
                            </div>
                        );
                    })}

                {typeof validateUserResult.error !== 'undefined' &&
                    validateUserResult?.error?.data?.errors?.map((error, index) => {
                        return (
                            <div className="bg-warning p-4" key={index}>
                                {error}
                            </div>
                        );
                    })}
            </>
        </main>
    )
};

export default Auth;
