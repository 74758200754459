import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './store';
import Home from "./components/Home";
import reportWebVitals from './reportWebVitals';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import ErrorLanding from "./components/Errors";
import Commits from "./components/Datasets";
import Auth from "./components/Home/Auth";
import Apps from "./components/Developers/Apps";
import NewApp from "./components/Developers/Apps/NewApp";
import SingleAppLanding from "./components/Developers/Apps/SingleAppLanding";
import Learn from "./components/Learn";
import Schemas from "./components/Datasets/Schemas";
import Snapshots from "./components/Datasets/Snapshots";
import Copilot from "./components/Developers/Apps/Copilot";
import Landing from "./components/Home/Landing";
import PRDDisplay from "./components/Developers/Apps/PRDDisplay";
import AIEditor from './components/Developers/Apps/AIEditor';

const router = createBrowserRouter([
    {
        path: "/!",
        element: <Home />,
        errorElement: <ErrorLanding />,
        children: [
            {
                path: "/!/learn",
                element: <Learn />
            },
            {
                path: "/!/copilot",
                element: <Copilot />
            },
            {
                path: "/!/copilot/:uuid",
                element: <PRDDisplay />
            },
            {
                path: "/!",
                element: <Apps />
            },
            {
                path: "/!/apps/new",
                element: <NewApp />
            },
            {
                path: "/!/apps/:uuid",
                element: <SingleAppLanding />
            },
            {
                path: "/!/apps/:uuid/edit",
                element: <NewApp />
            },
            {
                path: "/!/data",
                element: <Commits />
            },
            {
                path: "/!/data/snapshots",
                element: <Snapshots />
            },
            {
                path: "/!/data/schemas",
                element: <Schemas />
            },
            {
                path: "/!/ide/:uuid",
                element: <AIEditor />
            },
        ]
    },
    {
        path: "/",
        element: <Landing />
    },
    {
        path: "/auth",
        element: <Auth />
    }
]);
const container = document.getElementById('top');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
        <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);
reportWebVitals();
